<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-18 18:44:35
 * @FilePath: /mediatom-web/src/views/reportdatas/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="reportdatas-container fixed_container">
    <a-menu v-model="current" mode="horizontal" class="menu_group fixed_menu">
      <a-menu-item key="/reportdatas/synthreport" v-if="allRoutes.includes('synthreport')">
        <router-link :to="{ name: 'synthreport' }">综合报表</router-link>
      </a-menu-item>
      <a-menu-item key="/reportdatas/hoursreport" v-if="allRoutes.includes('hoursreport')">
        <router-link :to="{ name: 'hoursreport' }">小时报表</router-link>
      </a-menu-item>
      <a-menu-item key="/reportdatas/funnelreport" v-if="allRoutes.includes('funnelreport')">
        <router-link :to="{ name: 'funnelreport' }">漏斗报表</router-link>
      </a-menu-item>
      <a-menu-item key="/reportdatas/thirdParty" v-if="allRoutes.includes('thirdParty')">
        <router-link :to="{ name: 'thirdParty' }">上传第三方数据</router-link>
      </a-menu-item>
      <a-menu-item key="/reportdatas/mediareport" v-if="allRoutes.includes('mediareport')">
        <router-link :to="{ name: 'mediareport' }">媒体报表</router-link>
      </a-menu-item>
      <a-menu-item key="/reportdatas/useractionreport" v-if="allRoutes.includes('useractionreport')">
        <router-link :to="{ name: 'useractionreport' }">用户行为报表</router-link>
      </a-menu-item>
      <a-menu-item key="/reportdatas/datawarning" v-if="allRoutes.includes('datawarning')">
        <router-link :to="{ name: 'datawarning' }">预警报表</router-link>
      </a-menu-item>
      <a-menu-item key="/reportdatas/customerreport" v-if="allRoutes.includes('customerreport')">
        <router-link :to="{ name: 'customerreport' }">直投报表</router-link>
      </a-menu-item>
      <a-menu-item key="/reportdatas/usersretentionreport" v-if="allRoutes.includes('usersretentionreport')">
        <router-link :to="{ name: 'usersretentionreport' }">留存报表</router-link>
      </a-menu-item>
      <a-menu-item key="/reportdatas/mediasettle" v-if="allRoutes.includes('mediasettle')">
        <router-link :to="{ name: 'mediasettle' }">媒体结算</router-link>
      </a-menu-item>
      <a-menu-item key="/reportdatas/errorDiagnose" v-if="allRoutes.includes('errorDiagnose')">
        <router-link :to="{ name: 'errorDiagnose' }">异常诊断</router-link>
      </a-menu-item>
      <!-- <a-menu-item key="/reportdatas/topReport" v-if="allRoutes.includes('topReport')">
        <router-link :to="{ name: 'topReport' }">Top报表</router-link>
      </a-menu-item> -->
      <a-menu-item key="/reportdatas/adxcost" v-if="allRoutes.includes('adxcost')">
        <router-link :to="{ name: 'adxcost' }">MergeX消耗</router-link>
      </a-menu-item>
    </a-menu>
    <route-view></route-view>
  </div>
</template>
<script>
import { RouteView } from '@/layouts'
import { mapState } from 'vuex'
export default {
  name: 'Reportdatas',
  components: {
    RouteView
  },
  data () {
    return {
      current: [],
      otherPath: []
    }
  },
  computed: {
    ...mapState({
      // 权限路由集合
      allRoutes: (state) => state.permission.allRoutes
    })
  },
  watch: {
    $route: {
      handler (val) {
        const routes = val.matched.concat()
        this.current = [routes.pop().path]
      },
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep.reportdatas-container {
  background-color: #f7f7f7;
  // min-width: 1280px;
}
</style>
<style lang="less">
// 日期选择器
.reportdatas-container .ant-calendar-picker-input.ant-input {
  padding: 4px 3px;
  .ant-calendar-range-picker-input {
    font-size: 12px;
    width: 40%;
  }
}
</style>
